/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Content = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
      <Typography variant='h4' align='center'>
        “Good Triumphs over Evil”
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Celebrating Festivals is an integral part of learning at National Public School Yeshwanthpur. Keeping up to this tradition the festival of Dussera coupled with Nutrition Day was observed on Friday 23 September 2022 by the students and teachers of Pre-Primary. As part of the event, students viewed the traditional doll display- ‘GOLU’, recited shlokas and watched a video on the significance of this festival. The gathering brought the Pre-Primary community together; building a close bond between children and helping them connect with the values of our culture.
<br/><br/>
Nutrition day is a great opportunity to initiate and drive the importance of developing a healthy palate amongst young students in their formative years. Students were encouraged to get delicacies made of millets which introduced them to a great variety of healthy and tasty food.
<br/><br/>
Students were dressed in bright and vibrant traditional attire. The excitement multiplied with the Principals visit to the classes and their address brought happiness amongst everyone present.
        </Typography>
        <br />
     

    
        <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
          “Eat Healthy Think Better…”
        </Typography>
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
